import { ThemeType } from '@share/enums';
import FooterLogo from '../images/logo.webp';
import HeaderLogo from '../images/logo.webp';

const VectorLogo = undefined;

const defaultTheme = ThemeType.LIGHT;
const hasThemeSwitcher = false;
const hasNetworkSwitcher = false;
const hasWhatsappWidget = false;
const hasBanner = true;

const headerLogoSize = 180;
const DarkHeaderLogo = HeaderLogo;
const DarkFooterLogo = FooterLogo;
const footerLogoSize = 180;

export {
  VectorLogo,
  HeaderLogo,
  DarkHeaderLogo,
  headerLogoSize,
  FooterLogo,
  DarkFooterLogo,
  footerLogoSize,
  defaultTheme,
  hasThemeSwitcher,
  hasNetworkSwitcher,
  hasWhatsappWidget,
  hasBanner,
};
