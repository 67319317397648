import * as Sentry from '@sentry/react';
import { APIErrorStatus } from '@share/enums';
import { API_ERROR_MSG } from '@share/constant';
import { cache } from '@state/cache';
import { createContainer } from 'unstated-next';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { user } from '@state/user';
import { web3 } from '@state/web3';
import axios from 'axios';
import type { Activity } from '@share/interfaces';
import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import type {
  BackendPaginatedResponse,
  Balance,
  BalanceDetail,
  BalanceSummary,
  BindWalletArgs,
  CancelOrderArgs,
  ChangePasswordArgs,
  CheckoutBidArgs,
  CheckoutBuyArgs,
  CheckoutMintArgs,
  Collection,
  CollectionDetail,
  CollectionRelation,
  Config,
  CreateAuctionArgs,
  CreateSaleArgs,
  CurrencyData,
  FAQ,
  FlexibleMintSubmitRecord,
  FlexibleMintSubmitRecordDetail,
  GasReport,
  GetBidHistoryQueryArgs,
  GetCollectionHistoryQueryArgs,
  GetCollectionQueryArgs,
  GetEmailOtpArgs,
  GetFlexibleMintSubmitListQueryArgs,
  GetLaunchpadQueryArgs,
  GetNftHistoryQueryArgs,
  GetNftListQueryArgs,
  GetPhoneOtpArgs,
  GetSearchResultQueryArgs,
  GetUserCollectionQueryArgs,
  GetUserHistoryQueryArgs,
  GetUserNftQueryArgs,
  GkashBidArgs,
  GkashBuyArgs,
  GkashMintArgs,
  GkashPaymentDetail,
  HomeBanner,
  KycStatus,
  Launchpad,
  LaunchpadDetail,
  LoginData,
  MintStatus,
  Nft,
  NftDetail,
  NftDetailWithoutAttr,
  NftEvent,
  Order,
  ProcessCheckoutResult,
  Receipt,
  RegisterArgs,
  StripeBidArgs,
  StripeBuyArgs,
  StripeMintArgs,
  StripePaymentDetail,
  SubmitBankKycArgs,
  SubmitEmailKycArgs,
  SubmitFlexibleMintFormArgs,
  SubmitFlexibleMintResult,
  SubmitIdKycArgs,
  SubmitPhoneKycArgs,
  TakeHighestBidArgs,
  UpdateProfileArgs,
  User,
  VerifyEmailOtpArgs,
  VerifyPhoneOtpArgs,
  WithdrawData,
  WithdrawDepositResult,
  WithdrawNftArgs,
} from '@interface/api';

const { REACT_APP_API_BASE_URL, REACT_APP_DEFAULT_CHAINID } = process.env;

axios.defaults.baseURL = `${REACT_APP_API_BASE_URL}/api/v1`;
axios.defaults.transformResponse = (response: string) => {
  try {
    const parsedRes = JSON.parse(response);
    // External API may not follow .data practice, so return raw response if data == null
    return parsedRes.data != null ? parsedRes.data : parsedRes;
  } catch (error) {
    return response;
  }
};
const authInstance = axios.create();
let __userData: LoginData | null = null;

function useAPI() {
  const [defaultResInterceptor, setDefaultResInterceptor] = useState<number>(0);
  const [authResInterceptor, setAuthResInterceptor] = useState<number>(0);

  const { userData, language } = user.useContainer();
  const { updateTurnstileKey } = cache.useContainer();
  const { disconnect, chainId: connectedChainId } = web3.useContainer();
  const { t, i18n } = useTranslation();
  const { _paramsChainId } = useParams();
  const location = useLocation();

  // Auto fill-in all undefined chain_id and uuid
  const handleDefaultRequestConfig = (config: InternalAxiosRequestConfig) => {
    const autoAddData = (key: string, condition: unknown, value: unknown) => {
      const { params, data } = config;
      if (params && key in params && params[key] === condition)
        params[key] = value;
      if (data && key in data && data[key] === condition) data[key] = value;
    };

    // Params is the highest piority
    autoAddData(
      'chain_id',
      undefined,
      Number(_paramsChainId) ||
        connectedChainId ||
        Number(REACT_APP_DEFAULT_CHAINID)
    );
    return config;
  };

  // Auto show error alert box when API status code is 4xx
  const handleGlobalResponseError = (error: AxiosError) => {
    const { response } = error;
    const httpErrorCode = Number(response?.status) || 500;

    const httpErrorMsgList: Record<number, string> = {
      401: t('error.401'),
      // 404: t('error.404'), // Already use animation to handle it, no toast require
      422: t('error.422'),
      500: t('error.500'),
      504: t('error.504'),
    };

    const appErrorMsgList: Record<number, string | boolean> = {
      [APIErrorStatus.USER_NOT_FOUND]: false,
      [APIErrorStatus.COLLECTION_NOT_FOUND]: false,
      [APIErrorStatus.LAUNCHPAD_NOT_FOUND]: false,
      [APIErrorStatus.EMAIL_ALREADY_EXISTS]: t(
        'toastMessage.emailAlreadyExists'
      ),
      [APIErrorStatus.PARENT_CONTRACT_NOT_OWNED]: t(
        'toastMessage.parentContractNotOwned'
      ),
      [APIErrorStatus.EMAIL_NOT_FOUND]: t('toastMessage.emailNotFound'),
      [APIErrorStatus.ORDER_NOT_ACTIVE]: t('toastMessage.orderNotActive'),
      [APIErrorStatus.ORDER_NOT_FOUND]: t('toastMessage.orderNotActive'),
      [APIErrorStatus.WALLET_IS_NOT_TREASURY]: t(
        'toastMessage.needTransferToTreasury'
      ),
      [APIErrorStatus.WITHDRAW_NFT_PROCESSING]: t('modal.beingTakeAway'),
      [APIErrorStatus.USER_NOT_ID_KYC]: t('toastMessage.needIdKYCFirst'),
      [APIErrorStatus.USER_NOT_ACCOUNT_KYC]: t(
        'toastMessage.needEmailKYCFirst'
      ),
      [APIErrorStatus.USER_NOT_BANK_KYC]: t('toastMessage.needBankKYCFirst'),
      [APIErrorStatus.EMAIL_CODE_NOT_MATCH]: t('toastMessage.codeError'),
      [APIErrorStatus.BALANCE_AMOUNT_NOT_ENOUGH]: t(
        'errorMsg.balance.insufficient'
      ),
      [APIErrorStatus.BALANCE_AMOUNT_MAX]: t('errorMsg.balance.belowMinimum'),
      [APIErrorStatus.ORDER_PRICE_NOT_MATCH]: t('modal.transactionFeeUpdated'),
      [APIErrorStatus.ORDER_BID_PRICE_ERROR]: t('alertMessage.hasLatestPrice'),
      [APIErrorStatus.ORDER_PRICE_ERROR]: t('modal.checkMinBid'),
      [APIErrorStatus.USER_LOGIN_INFO_ERROR]: t('toastMessage.loginError'),
      [APIErrorStatus.EMAIL_LINK_INVALID]: t('toastMessage.emailLinkInvalid'),
      [APIErrorStatus.LAUNCHPAD_NOT_MEET_PHASE_RULE]: t(
        'toastMessage.mintTimeNotCorrect'
      ),
      [APIErrorStatus.LAUNCHPAD_PHASE_SOLD_OUT]: t(
        'toastMessage.launchpadPhaseSoldOut'
      ),
      [APIErrorStatus.EMAIL_ALREADY_SENT]: t(
        'toastMessage.verifyEmailAlreadySent'
      ),
      [APIErrorStatus.TURNSTILE_FAILED]: t('toastMessage.turnstileIssue'),
      [APIErrorStatus.PARENT_COLLECTION_NFT_EXPIRED]: t(
        'toastMessage.parentExpired'
      ),
      [APIErrorStatus.COUNTRY_UNAVAILABLE]: t(
        'toastMessage.countryUnavailable'
      ),
      [APIErrorStatus.WALLET_ALREADY_CONNECTED]: t(
        'toastMessage.walletAccountBound'
      ),
      [APIErrorStatus.WALLET_NOT_DISCONNECT]: t('toastMessage.notUnbindWallet'),
      [APIErrorStatus.EMAIL_OTP_CODE_INVALID]: t(
        'toastMessage.emailOtpCodeInvalid'
      ),
      [APIErrorStatus.PHONE_OTP_NOT_FOUND]: t('toastMessage.phoneOtpNotFound'),
      [APIErrorStatus.USER_NOT_FOUND_TIPS]: t('toastMessage.userNotFound'),
      [APIErrorStatus.PHONE_ALREADY_EXISTS]: t(
        'toastMessage.phoneAlreadyExists'
      ),
    };

    updateTurnstileKey();

    // Display HTTP level error message
    if (httpErrorCode > 400) {
      const httpErrorMsg = httpErrorMsgList[httpErrorCode];
      if (!toast.isActive(API_ERROR_MSG) && httpErrorMsg) {
        // only show one popup at the same time
        toast.error(httpErrorMsg, { toastId: API_ERROR_MSG });
      }
      if (httpErrorCode === 401)
        disconnect('/login', { redirectTo: location.pathname });
    }

    // Display application level error message
    if (httpErrorCode === 400) {
      const data: any = response?.data;
      const appErrorCode = Number(data?.code);
      const appErrorMsg = appErrorMsgList[appErrorCode];
      // Only show one popup at the same time
      if (!toast.isActive(API_ERROR_MSG) && appErrorMsg !== false) {
        // data.message only show in the development environment
        toast.error(
          appErrorMsg ||
            data?.message ||
            t('errorMsg.unexcepted', { code: appErrorCode }),
          {
            toastId: API_ERROR_MSG,
            autoClose: appErrorMsg || data?.message ? 5000 : 10000,
          }
        );
      }
    }

    return Promise.reject(error);
  };

  // Auto add bearer token for all private API
  const handleAuthRequestConfig = (config: InternalAxiosRequestConfig) => {
    const { headers } = config;
    if (headers && __userData?.token)
      headers.Authorization = `Bearer ${__userData.token}`;
    return config;
  };

  useEffect(() => {
    axios.interceptors.request.use(handleDefaultRequestConfig);
    authInstance.interceptors.request.use(handleAuthRequestConfig);
    authInstance.interceptors.request.use(handleDefaultRequestConfig);
  }, []);

  /**
   * FIXME: Since there will have delay if we use same implementaion like
   * i18n.language (eject + add) and this delay will cause to use old UUID + no API key
   * after logging in, so we create a variable out of React scope and update
   * instantly in order to slove this issue first.
   */
  useEffect(() => {
    __userData = userData;
  }, [userData]);

  useEffect(() => {
    if (i18n.language) {
      axios.interceptors.response.eject(defaultResInterceptor);
      authInstance.interceptors.response.eject(authResInterceptor);
      setDefaultResInterceptor(
        axios.interceptors.response.use(undefined, handleGlobalResponseError)
      );
      setAuthResInterceptor(
        authInstance.interceptors.response.use(
          undefined,
          handleGlobalResponseError
        )
      );
    }
  }, [i18n.language]);

  const privateAPI = {
    /*
      Profile
    */
    getProfile: async (uuid: string, chainId?: number) => {
      // Backend will check is profile owner or not to return different value
      const { data } = await authInstance.post<User>('/profile/query', {
        chain_id: chainId,
        uuid,
      });
      return data;
    },
    updateProfile: async (args: UpdateProfileArgs) => {
      const { data } = await authInstance.post<User>('/profile/update', {
        name: args.name,
        bio: args.bio,
        personal_site: args.personalSite,
        social_link_facebook: args.facebookLink,
        social_link_twitter: args.twitterLink,
        social_link_discord: args.discordLink,
        language: args.language,
      });
      return data;
    },
    // Same route with isUsernameValid, but this api will use authInstance (filter current username)
    isProfileNameValid: async (name: string) => {
      const { data: isDuplicate } = await authInstance.post<boolean>(
        '/profile/check-user',
        {
          name,
        }
      );
      return isDuplicate;
    },
    updateAvatar: async (avatar?: File) => {
      const formData = new FormData();
      if (avatar) formData.append('avatar', avatar);
      const { data } = await authInstance.post<Exclude<User, 'wallet'>>(
        '/profile/update-avatar',
        formData
      );
      return data;
    },
    setNftAsAvatar: async (
      chainId: number,
      collectionAddress: string,
      tokenId: number
    ) => {
      const { data } = await authInstance.post<User>(
        '/profile/update-avatar-nft',
        {
          chain_id: chainId,
          collection_address: collectionAddress,
          token_id: tokenId,
        }
      );
      return data;
    },
    submitIdKyc: async (args: SubmitIdKycArgs) => {
      const formData = new FormData();
      formData.append('card_image', args.cardImage);
      formData.append('country', args.country);
      formData.append('full_name', args.fullName);
      formData.append('card_no', args.cardNumber);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-submit',
        formData
      );
      return data;
    },
    cancelIdKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/id-cancel'
      );
      return data;
    },
    submitBankKyc: async (args: SubmitBankKycArgs) => {
      const formData = new FormData();
      formData.append('country', args.country);
      formData.append('bank_name', args.bankName);
      formData.append('bank_account_no', args.bankAccountNumber);
      formData.append('bank_document_image', args.cardImage);
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-submit',
        formData
      );
      return data;
    },
    cancelBankKyc: async () => {
      const { data } = await authInstance.post<KycStatus>(
        '/auth/kyc/bank-cancel'
      );
      return data;
    },
    changePassword: async (args: ChangePasswordArgs) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/auth/reset-password/reset',
        {
          u: args.uuid,
          k: args.oneTimeKey,
          password: args.password,
          password_confirmation: args.password,
        }
      );
      return isSuccess;
    },
    sendResetPasswordEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/reset-password/send',
        { email }
      );
      return isSuccess;
    },
    sendVerifyAccountEmail: async (email: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify/resend',
        { email }
      );
      return isSuccess;
    },
    verifyAccount: async (uuid: string, oneTimeKey: string) => {
      const { data: isSuccess } = await authInstance.post<true>(
        '/auth/email/verify',
        {
          u: uuid,
          k: oneTimeKey,
        }
      );
      return isSuccess;
    },
    logout: async () => {
      try {
        await authInstance.post('/auth/logout');
      } catch (error) {
        // FIXME: Have to use try catch to ensure not blocking disconnect process
        Sentry.captureException(error);
      }
    },
    submitPhoneKyc: async (args: SubmitPhoneKycArgs) => {
      const formData = new FormData();
      formData.append('phone', args.phone);
      formData.append('code', args.code);
      formData.append('phone_country', args.phone_country);
      await authInstance.post<KycStatus>('/auth/kyc/phone-submit', formData);
    },
    cancelPhoneKyc: async (code: string) => {
      const formData = new FormData();
      formData.append('code', code);
      await authInstance.post<KycStatus>('/auth/kyc/phone-cancel', formData);
    },
    bindingWallet: async (args: BindWalletArgs) => {
      const { data } = await authInstance.post<User>(
        '/auth/connect_new_wallet',
        {
          address: args.address,
          signature: args.signature,
          nonce: args.nonce,
        }
      );
      return data;
    },
    unbindingWallet: async (args: BindWalletArgs) => {
      const { data } = await authInstance.post<User>(
        '/auth/disconnect_wallet',
        {
          address: args.address,
          signature: args.signature,
          nonce: args.nonce,
        }
      );
      return data;
    },
    submitEmailKyc: async (args: SubmitEmailKycArgs) => {
      const formData = new FormData();
      formData.append('email', args.email);
      formData.append('code', args.code);
      await authInstance.post<KycStatus>('/auth/kyc/email-submit', formData);
    },
    cancelEmailKyc: async (code: string) => {
      const formData = new FormData();
      formData.append('code', code);
      await authInstance.post<KycStatus>('/auth/kyc/email-cancel', formData);
    },
    /*
      Receipt
    */
    getReceiptList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<
        BackendPaginatedResponse<Receipt[]>
      >('/receipt/list', {
        cursor: pageParam,
      });
      return data;
    },
    getReceiptDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<Receipt>('/receipt/query', {
        no: invoiceId,
      });
      return data;
    },
    /*
      Balance
    */
    getBalance: async () => {
      const { data } =
        await authInstance.post<BalanceSummary[]>('/balance/data');
      return data;
    },
    getBalanceList: async ({ pageParam }: { pageParam?: string }) => {
      const { data } = await authInstance.post<
        BackendPaginatedResponse<Balance[]>
      >('/balance/list', {
        cursor: pageParam,
      });
      return data;
    },
    getBalanceDetail: async (invoiceId: string) => {
      const { data } = await authInstance.post<BalanceDetail>(
        '/balance/query',
        {
          no: invoiceId,
        }
      );
      return data;
    },
    withdrawDeposit: async (amount: number, currency: string) => {
      const { data } = await authInstance.post<WithdrawDepositResult>(
        '/balance/request',
        {
          amount,
          currency,
        }
      );
      return data;
    },
    /*
      Gas
    */
    getGasReport: async (orderId: number, walletAddress: string) => {
      const { data } = await authInstance.post<GasReport>(
        '/marketplace/check-order',
        {
          order_id: orderId,
          wallet_address: walletAddress,
        }
      );
      return data;
    },
    /*
      Checkout Payment
    */
    processCheckoutMint: async (args: CheckoutMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<ProcessCheckoutResult>(
          '/launchpad/phase/mint_nft',
          {
            wallet_address: args.walletAddress,
            amount: args.amount,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
            card_token: args.cardToken,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<ProcessCheckoutResult | Receipt>(
        '/launchpad/mint_nft',
        {
          wallet_address: args.walletAddress,
          amount: args.amount,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
          card_token: args.cardToken,
        }
      );
      return data;
    },
    processCheckoutBuy: async (args: CheckoutBuyArgs) => {
      const { data } = await authInstance.post<ProcessCheckoutResult>(
        '/marketplace/buy-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          buyer_address: args.buyerAddress,
          total_price: args.totalPrice,
          card_token: args.cardToken,
        }
      );
      return data;
    },
    processCheckoutBid: async (args: CheckoutBidArgs) => {
      const { data } = await authInstance.post<ProcessCheckoutResult>(
        '/marketplace/bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          price: args.bidPrice,
          total_price: args.totalPrice,
          bidder_address: args.bidderAddress,
          card_token: args.cardToken,
        }
      );
      return data;
    },
    /*
      Gkash Payment
    */
    processGkashMint: async (args: GkashMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<GkashPaymentDetail>(
          '/launchpad/phase/mint_nft',
          {
            wallet_address: args.walletAddress,
            amount: args.amount,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/launchpad/mint_nft',
        {
          wallet_address: args.walletAddress,
          amount: args.amount,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processGkashBuy: async (args: GkashBuyArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/buy-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          buyer_address: args.buyerAddress,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processGkashBid: async (args: GkashBidArgs) => {
      const { data } = await authInstance.post<GkashPaymentDetail>(
        '/marketplace/bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          price: args.bidPrice,
          total_price: args.totalPrice,
          bidder_address: args.bidderAddress,
        }
      );
      return data;
    },
    /*
      Stripe Payment
    */
    processStripeMint: async (args: StripeMintArgs) => {
      if (args.submitId) {
        const { data } = await authInstance.post<StripePaymentDetail>(
          '/launchpad/phase/mint_nft',
          {
            wallet_address: args.walletAddress,
            amount: args.amount,
            submit_uuid: args.submitId,
            total_price: args.totalPrice,
          }
        );
        return data;
      }

      const { data } = await authInstance.post<StripePaymentDetail>(
        '/launchpad/mint_nft',
        {
          wallet_address: args.walletAddress,
          amount: args.amount,
          phase_uuid: args.phase,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processStripeBuy: async (args: StripeBuyArgs) => {
      const { data } = await authInstance.post<StripePaymentDetail>(
        '/marketplace/buy-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          buyer_address: args.buyerAddress,
          total_price: args.totalPrice,
        }
      );
      return data;
    },
    processStripeBid: async (args: StripeBidArgs) => {
      const { data } = await authInstance.post<StripePaymentDetail>(
        '/marketplace/bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress,
          token_id: args.tokenId,
          price: args.bidPrice,
          total_price: args.totalPrice,
          bidder_address: args.bidderAddress,
        }
      );
      return data;
    },
    /*
      Market
    */
    createSale: async (args: CreateSaleArgs) => {
      const { data } = await authInstance.post<Order>(
        '/marketplace/create-sell-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          payment_method: args.paymentMethodId,
          seller_address: args.sellerAddress,
          quality: args.quantity, // typo issue
          selling_price: args.sellingPrice,
        }
      );
      return data;
    },
    createAuction: async (args: CreateAuctionArgs) => {
      const { data } = await authInstance.post<Order>(
        '/marketplace/create-auction-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          payment_method: args.paymentMethodId,
          seller_address: args.sellerAddress,
          quality: args.quantity, // typo issue
          minimum_bid: args.floorPrice,
          end_date: args.endTimestamp,
          bid_increase_percentage: args.bidIncreasePercentage,
        }
      );
      return data;
    },
    takeHighestBid: async (args: TakeHighestBidArgs) => {
      const { data } = await authInstance.post<Activity>(
        '/marketplace/accept-bid-order',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          order_id: args.orderId,
        }
      );
      return data;
    },
    cancelOrder: async (args: CancelOrderArgs) => {
      const { data } = await authInstance.post<Activity>(
        '/marketplace/order-cancel',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          order_id: args.orderId,
        }
      );
      return data;
    },
    getWithdrawNftCode: async (args: WithdrawData) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/profile/withdraw-nft/get-code',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          // Here not changing the key for backward compatibility, should support email also
          wallet_address: args.receiver?.toLowerCase(),
        }
      );
      return isSuccess;
    },
    withdrawNft: async (args: WithdrawNftArgs) => {
      const { data: isSuccess } = await authInstance.post<boolean>(
        '/profile/withdraw-nft/submit',
        {
          chain_id: args.chainId,
          collection_address: args.collectionAddress?.toLowerCase(),
          token_id: args.tokenId,
          wallet_address: args.receiver?.toLowerCase(),
          code: args.code,
        }
      );
      return isSuccess;
    },
    /*
      Flexible Mint
    */
    submitFlexibleMintForm: async (args: SubmitFlexibleMintFormArgs) => {
      const formData = new FormData();

      formData.append('wallet_address', args.walletAddress);
      formData.append('phase_uuid', args.phaseId);

      for (const [type, data] of Object.entries(args.metadata)) {
        for (const [key, value] of Object.entries(data)) {
          formData.append(
            `${type}[${key}]`,
            value instanceof FileList ? value[0] : String(value)
          );
        }
      }

      const { data } = await authInstance.post<SubmitFlexibleMintResult>(
        '/launchpad/phase/submit',
        formData
      );
      return data;
    },
    getFlexibleMintSubmitList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetFlexibleMintSubmitListQueryArgs;
      pageParam?: string;
    }) => {
      const { launchpadId, phaseId } = queryKey[1];
      const { data } = await authInstance.post<
        BackendPaginatedResponse<FlexibleMintSubmitRecord[]>
      >('/launchpad/phase/record-list', {
        uuid: launchpadId,
        phase_uuid: phaseId,
        cursor: pageParam,
      });
      return data;
    },
    getFlexibleMintSubmitDetail: async (recordId: string) => {
      const { data } = await authInstance.post<FlexibleMintSubmitRecordDetail>(
        '/launchpad/phase/record',
        {
          submit_uuid: recordId,
        }
      );
      return data;
    },
    /*
      Others
    */
    getLoginStatus: async () => {
      const { data: isLogin } = await authInstance.get<true>('/auth/status');
      return isLogin;
    },
    getKycStatus: async () => {
      const { data } = await authInstance.get<KycStatus>('/auth/kyc/status');
      return data;
    },
    getMintStatus: async (phaseId: string, address: string) => {
      const { data } = await authInstance.post<MintStatus>('/launchpad/check', {
        wallet_address: address?.toLowerCase(),
        phase_uuid: phaseId,
      });
      return data;
    },
    getNftQRCode: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<string>(
        '/event/nft/owner-qrcode',
        {
          chain_id: chainId,
          address: address?.toLowerCase(),
          token_id: tokenId,
        }
      );
      return data;
    },
    getNftEvents: async (chainId: number, address: string, tokenId: number) => {
      const { data } = await authInstance.post<NftEvent[]>(
        '/event/nft/events',
        {
          chain_id: chainId,
          address: address?.toLowerCase(),
          token_id: tokenId,
        }
      );
      return data;
    },
    getLaunchpadDetails: async (uuid: string, chainId?: number) => {
      // Use auth instance for checking this user minted amount > user quota or not
      const { data } = await authInstance.post<LaunchpadDetail>(
        '/launchpad/detail',
        {
          chain_id: chainId,
          uuid, // Accept UUID or custom URL
        }
      );
      return data;
    },
  };

  const publicAPI = {
    /*
      Auth
    */
    emailLogin: async (
      email: string,
      password: string,
      turnstileResponse: string
    ) => {
      const { data } = await axios.post<LoginData>('/auth/login', {
        email,
        password,
        cf_turnstile_response: turnstileResponse,
      });
      return data;
    },
    getPhoneOtp: async ({
      cca2,
      e164Phone,
      turnstileResponse,
      action = 'login_or_register',
    }: GetPhoneOtpArgs) => {
      const { data: isSuccess } = await axios.post<boolean>(
        '/auth/phone-otp/send',
        {
          action,
          phone: e164Phone,
          phone_country: cca2,
          cf_turnstile_response: turnstileResponse,
        }
      );
      return isSuccess;
    },
    verifyPhoneOtp: async ({
      cca2,
      e164Phone,
      otpCode,
    }: VerifyPhoneOtpArgs) => {
      const { data } = await axios.post<LoginData>('/auth/phone-otp/verify', {
        phone: e164Phone,
        phone_country: cca2,
        code: otpCode,
      });
      return data;
    },
    getEmailOtp: async ({
      email,
      turnstileResponse,
      action = 'login_or_register',
    }: GetEmailOtpArgs) => {
      const { data: isSuccess } = await axios.post<boolean>(
        '/auth/email-otp/send',
        {
          action,
          email,
          cf_turnstile_response: turnstileResponse,
        }
      );
      return isSuccess;
    },
    verifyEmailOtp: async ({ email, otpCode }: VerifyEmailOtpArgs) => {
      const { data } = await axios.post<LoginData>('/auth/email-otp/verify', {
        email,
        code: otpCode,
      });
      return data;
    },
    emailRegister: async (args: RegisterArgs) => {
      const { data } = await axios.post<LoginData>('/auth/register', {
        name: args.name,
        email: args.email,
        password: args.password,
        password_confirmation: args.confirmPassword,
        cf_turnstile_response: args.turnstileResponse,
        language,
      });
      return data;
    },
    isUsernameValid: async (name: string) => {
      const { data: isDuplicate } = await axios.post<boolean>(
        '/auth/check-user',
        { name }
      );
      return isDuplicate;
    },
    /*
      Index
    */
    getBannerList: async () => {
      const { data } = await axios.get<HomeBanner[]>('/system/banner');
      return data;
    },
    getPopularCollection: async (chainId?: number) => {
      const { data } = await axios.get<BackendPaginatedResponse<Collection[]>>(
        '/collection',
        {
          params: { chain_id: chainId },
        }
      );
      return data.data?.slice(0, 3);
    },
    getSearchResult: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetSearchResultQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, keyword, category } = queryKey[1];
      const { data } = await axios.post<
        BackendPaginatedResponse<(Collection | User | Nft)[]>
      >(`/search/${category}`, {
        chain_id: chainId,
        q: keyword,
        cursor: pageParam,
      });
      return data;
    },
    getLiveOrder: async (chainId?: number) => {
      const { data } = await axios.get<NftDetailWithoutAttr[]>(
        '/marketplace/live-order',
        {
          params: { chain_id: chainId },
        }
      );
      return data;
    },
    /*
      Launchpad
    */
    getLaunchpadList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetLaunchpadQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, category } = queryKey[1];
      const { data } = await axios.get<BackendPaginatedResponse<Launchpad[]>>(
        '/launchpad',
        {
          params: {
            chain_id: chainId,
            category,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    /*
      Collection
    */
    getCollectionList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetCollectionQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, category } = queryKey[1];
      const { data } = await axios.get<BackendPaginatedResponse<Collection[]>>(
        '/collection',
        {
          params: {
            chain_id: chainId,
            category,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    getCollectionDetails: async (
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await axios.post<CollectionDetail>(
        '/collection/detail',
        {
          address: collectionAddress?.toLowerCase(), // Accept address or custom URL
          chain_id: chainId,
        }
      );
      return data;
    },
    getCollectionActivity: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetCollectionHistoryQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, address } = queryKey[1];
      const { data } = await axios.post<BackendPaginatedResponse<Activity[]>>(
        '/collection/activity',
        {
          chain_id: chainId,
          address: address?.toLowerCase(),
          cursor: pageParam,
        }
      );
      return data;
    },
    getCollectionRelation: async (
      chainId: number,
      collectionAddress: string
    ) => {
      const { data } = await axios.post<CollectionRelation>(
        '/collection/parent-record',
        {
          chain_id: chainId,
          address: collectionAddress?.toLowerCase(),
        }
      );
      return data;
    },
    getNftList: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetNftListQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, collectionAddress, filter, sort, attributes } =
        queryKey[1];

      const search = attributes
        ?.map(
          (item) =>
            `search[${encodeURIComponent(
              item.category
            )}][]=${encodeURIComponent(item.value)}`
        )
        .join('&');

      const { data } = await axios.get<Nft[]>(
        `/marketplace/order-list${search ? `?${search}` : ''}`,
        {
          params: {
            chain_id: chainId,
            collection_address: collectionAddress?.toLowerCase(),
            filter,
            sort,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    getNftDetails: async (
      tokenId: number,
      collectionAddress: string,
      chainId?: number
    ) => {
      const { data } = await axios.post<NftDetail>(
        '/marketplace/order-detail',
        {
          token_id: tokenId,
          collection_address: collectionAddress?.toLowerCase(),
          chain_id: chainId,
        }
      );
      return data;
    },
    getNftHistory: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetNftHistoryQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, collectionAddress, tokenId } = queryKey[1];
      const { data } = await axios.post<BackendPaginatedResponse<Activity[]>>(
        '/collection/nft/activity',
        {
          chain_id: chainId,
          collection_address: collectionAddress?.toLowerCase(),
          token_id: tokenId,
          cursor: pageParam,
        }
      );
      return data;
    },
    getBidHistory: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetBidHistoryQueryArgs;
      pageParam?: string;
    }) => {
      const { orderId } = queryKey[1];
      const { data } = await axios.get<BackendPaginatedResponse<Activity[]>>(
        '/marketplace/bid-record',
        {
          params: {
            order_id: orderId,
            cursor: pageParam,
          },
        }
      );
      return data;
    },
    /*
      User
    */
    getUserCollection: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetUserCollectionQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, uuid, category } = queryKey[1];
      const { data } = await axios.post<BackendPaginatedResponse<Collection[]>>(
        '/profile/user_collection',
        {
          chain_id: chainId,
          uuid,
          category,
          cursor: pageParam,
        }
      );
      return data;
    },
    getUserNft: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetUserNftQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, uuid, type, sort, itemsPerPage, search, category } =
        queryKey[1];
      const { data } = await axios.post<
        BackendPaginatedResponse<NftDetailWithoutAttr[]>
      >('/profile/user_nft', {
        chain_id: chainId,
        uuid,
        type,
        sort,
        limit: itemsPerPage,
        search,
        category,
        cursor: pageParam,
      });
      return data;
    },
    getUserActivity: async ({
      queryKey,
      pageParam,
    }: {
      queryKey: GetUserHistoryQueryArgs;
      pageParam?: string;
    }) => {
      const { chainId, uuid } = queryKey[1];
      const { data } = await axios.post<BackendPaginatedResponse<Activity[]>>(
        '/profile/activity',
        {
          chain_id: chainId,
          uuid,
          cursor: pageParam,
        }
      );
      return data;
    },
    /*
      System
    */
    getCurrencyList: async (chainId?: number, isActive = true) => {
      const { data } = await axios.get<CurrencyData[]>('/currency', {
        params: {
          chain_id: chainId,
          is_active: isActive,
        },
      });
      return data;
    },
    getConfig: async (chainId?: number) => {
      // chain_id will affect currency
      const { data } = await axios.get<Config>('/system/config', {
        params: { chain_id: chainId },
      });
      return data;
    },
    getFAQ: async () => {
      const { data } = await axios.get<FAQ[]>('/system/faq');
      return data;
    },
  };

  // Prevent duplicate key on private and public API (key duplicate will overriding and hard to debug)
  const combine = <
    T extends object,
    U extends object & { [K in keyof T]?: undefined },
  >(
    obj1: T,
    obj2: U
  ) => {
    return {
      ...obj1,
      ...obj2,
    };
  };

  return { ...combine(privateAPI, publicAPI) };
}

export const api = createContainer(useAPI);
